// API URLS
export const WS_ADMIN = "/admin";
export const WS_ADMIN_UPDATE = "/admin/update";
export const WS_ADMIN_LOGIN = "/admin/login";
export const WS_ADMIN_LOGOUT = "/admin/logout";
export const WS_ADMIN_CHANGE_PASSWORD = "/admin/password";
export const WS_ADMIN_RESET_PASSWORD = "/admin/reset/admin";

export const WS_AGENT = "/user/admin";
export const WS_AGENT_UPDATE = "/agent/update";
export const WS_AGENT_LOGIN = "/agent/login";
export const WS_AGENT_LOGOUT = "/agent/logout";
export const WS_AGENT_CHANGE_PASSWORD = "/agent/password";
export const WS_AGENT_RESET_PASSWORD = "/admin/reset/agent";
export const WS_CUSTOMER = "/user/admin";
export const WS_CUSTOMER_BY_AGENT = "/customer/agent";
export const WS_CUSTOMER_UPDATE = "/customer/update";
export const WS_USER_UPDATE = "/user/update";
export const WS_PRODUCT = "/order/admin";
export const WS_PRODUCT_UPDATE = "/order/admin/update";
export const WS_COMMISSION_SETTING = "/commissionSetting";
export const WS_COMMISSION_SETTING_UPDATE = "/commissionSetting/update";
export const WS_SALE = "/sale";
export const WS_SALE_AGENT = "/sale/agent";
export const WS_SALE_UPDATE = "/sale/update";
export const WS_SALE_DELETE = "/sale/delete";
export const WS_DOWNLINE = "/downline";
export const WS_COMMISSION = "/commission";
export const WS_COMMISSION_ADMIN = "/commission/admin";
export const WS_COMMISSION_TOTAL = "/commission/total";
export const WS_REPORT_STATS = "/report/stats";
export const WS_REPORT_TOTAL_SALES = "/report/driverOrder";
export const WS_REPORT_AGENT_COMMISSION = "/agent/commission";
export const WS_REPORT_AGENT_SALES = "/agent/sales";
export const WS_REPORT_DAILY_SALES = "/report/orders";
export const WS_SALES_TOTAL = "/sale/total";

export const WS_USER_LIST = "/user/admin";
export const WS_PRICING_MODEL = "/pricingModel";
export const WS_PRICING_MODEL_UPDATE = "/pricingModel/update";
export const WS_DRIVER_REQUEST_LIST = "/driverRequest/admin";
export const WS_DRIVER_REQUEST_APPROVAL = "/driverRequest/approval";
export const WS_NEWS = "/news";
export const WS_NEWS_UPDATE = "/news/update";
export const WS_BANNER = "/banner";
export const WS_BANNER_UPDATE = "/banner/update";

// STATUS CODES
// 400 - Bad request (Missing params, body)
// 401 - Unauthorised (Bad token, etc.)
export const SUCCESS_RESPONSE_CODE = "200";
export const ERROR_RESPONSE_CODE = "500"; // HTTP CODE 200
export const INVALID_ACCESS_TOKEN_RESPONSE_CODE = "501";

export const KEY_USER_PROFILE = "ZUPrgwW6mv";
export const KEY_NEWS_ANNOUNCEMENT = "JrC4NRMlEC";
export const SPECIAL = "special";

export const KEDAH = 'kedah';
export const PENANG = 'penang';
export const PERLIS = 'perlis';
export const PERAK = 'perak';
export const KUALA_LUMPUR = 'kuala_lumpur';
export const SELANGOR = 'selangor';
export const NEGERI_SEMBILAN = 'negeri_sembilan';
export const MALACCA = 'malacca';
export const JOHOR = 'johor';
export const KELANTAN = 'kelantan';
export const PAHANG = 'pahang';
export const TERENGGANU = 'terengganu';